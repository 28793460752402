dt:before {
    content: "";
    display: block;
}

dt:after {
    content: ":\00a0";
}

dt,
dd {
    display: inline;
}